import React from 'react';
import Layout from '../../components/layout';
import { Link, graphql } from 'gatsby';
import { render } from 'node-sass';
import Img from 'gatsby-image';
import styled from "styled-components"

const Post = styled.div`
    display: flex;
    padding-left: 6rem;
    padding-right: 6rem;
    padding-bottom: 1rem;
    padding-top: 6rem;
`

const PostImage = styled.div`
    flex-basis: 25%;
    margin-right: 1rem;
`

const PostText = styled.div`
    flex-basis: 75%;
`

const BlogPosts = ({ data }) => {
  const blogPosts = data.allContentfulPost.edges;

    return (
        <Layout>
            {blogPosts.map(({ node: post}) => (
                <Post>
                    <PostImage>
                            <Img fluid={post.image[0].fluid} className="resources-size"></Img>
                    </PostImage>
                    <PostText>
                        <div className="blogposts">
                            <Link to={`/news/${post.slug}`}>
                                <h3>{post.title}</h3>
                                <p>{post.author}</p>
                            </Link>
                        </div>
                    </PostText>               
                </Post>
            ))}
        </Layout>
    )
}

export default BlogPosts

export const query = graphql`
  query BlogPostsPageQuery {
    allContentfulPost {
      edges {
        node {
          title
          author
          slug
          image {
            fluid {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
}
`
